/*
Theme Name: しらさぎ塾
Author: warrior_ito
Description: .
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
*/

@charset "utf-8";
//@import "moto_style";
$theme_color: #FF3300;
$link_color: blue;
$text_color: #000;
$base_color: #FFFDF4;
//$theme_fonts: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$theme_fonts: YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
//$headline_fonts: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$headline_fonts: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$content_width:1100px;

/*font size*/
@mixin fontSize($size, $base: 16) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
}

@mixin cf{
  &:after{
    content:" ";
    display:block;
    clear:both;
  }
}

.d_block{
  display: block;
}

.copy{
  font-size: 11px;
  color: #fff;
}
#header{
  p{
    margin: 0;
  }
}
.dib{
  display: inline-block;
}

.home-title{
  &.module{
    .module-title{
      div{
        padding: 5px 0px 7px 60px;
      }
      h3{
        @include fontSize(32);
        margin: 0;
        display: inline-block;
        line-height: 1;
      }
      a{
        background: url('../img/home-title-font-bg.png') no-repeat center left;
        margin-top: 30px;
      }
    }
  }
}
#pagewrap{
  .home-title{
    &.module{
      .module-title{
        a{
          margin-top: 25px;
        }
      }
    }
  }
}

.title2{
  &.module{
    .module-title{
      h3 {
        color: #fff;
        font-size: 20px;
        background: url('../img/title2-bg.jpg') no-repeat center left 1px, #0289cf;
        padding: 15px 0px 13px 25px;
        margin-bottom: 35px;
        font-family: Yu Gothic,Yu Gothic M,"メイリオ",sans-serif;
        margin-top: 0;
      }
    }
  }
}

.archive,.single{
  #main{
    margin-bottom: 30px;
  }
  .row_inner_wrapper{
    max-width: 1203px;
    margin-left: auto;
    margin-right: auto;
    .row_inner{
      display: flex;
      .col4-3{
        max-width: 78%;
        a{
          color: #018CCF;
        }
        .post-title{
          @include fontSize(18);
          border-bottom: dotted 1px #018CCF;
          padding: 10px 0;
        }
        .post-content{
          padding: 15px 0;
        }
        .other{
          text-align: right;
          p{
            display: inline-block;
            padding-left: 10px;
            @include fontSize(10);
            color: #a7a7a7;
          }
        }
      }
      .col4-1{
        max-width: 20%;
      }
    }
  }
}

.widget{
  background-color: #F0F0F0;
  padding: 10px;
  margin-bottom: 50px;
  .widgettitle{
    @include fontSize(18);
    margin: 0;
    color: #018CCF;
    padding-bottom: 5px;
  }
  ul{
    li{
      padding: 5px;
      border-bottom: solid 1px #EBEBEB;
    }
  }
}
/* body{
  font-family: $theme_fonts;
  color: $text_color;
  line-height:1.7;
  font-size:16px;
  background-color: #FFFDF4;
} */

.mw_wp_form{
  table{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    tr{
      th,td{
        padding: 20px 0;
        text-align: left;
        @include fontSize(15);
        border-bottom: solid 1px #e5e5e5;
        vertical-align: top;
      }
      th{
        .redbox{
          background-color: red;
          padding: 0 5px;
          color: #fff;
          display: inline-block;
          margin-left: 5px;
          @include fontSize(13);
        }
      }
      td{
        textarea{
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
  div{
      input[type="submit"]{
      background-color: #0289cf;
      border-radius: 10px;
      color: #fff;
      border: none;
      padding: 10px 20px;
      @include fontSize(18);
      display: block;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      margin-bottom: 60px;
    }
  }
}

.site{
  color: #333;
}

@media screen and (max-width: 680px){
  #headerwrap{
    margin-top: 0;
  }
  th,td{
    box-sizing: border-box;
  }
  #body .page6-table td:last-child{
    margin-bottom: 25px;
  }
  .mw_wp_form{
    table{
      box-sizing: border-box;
      tr{
        th,td{
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 5px;
          input:not([type="checkbox"]){
            width: 100%;
            box-sizing: border-box;
          }
          textarea{
            box-sizing: border-box;
          }
          .mwform-tel-field,.mwform-zip-field{
            input{
              width: inherit;
            }
          }
        }
        td{
          margin-bottom: 20px;
        }
      }
    }
  }
}

